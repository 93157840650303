import React, { useState, useCallback, useEffect } from "react";
import { getAllMatchSyncBrackets } from "../../apis/users";
import AddBracket from "./AddBracketModal";
import Tabs from "../../components/Tabs/Tabs";
import "./MatchSyncBrackets.css";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const MatchSyncBrackets = () => {
  const [data, setData] = useState({});
  const [editData, setEditData] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [activeTab, setActiveTab] = useState("mySync");

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAllMatchSyncBrackets();
      setData({ ...apiResponse?.data?.data });
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const editDataHandler = useCallback((item) => {
    setEditData(item);
  }, []);

  return (
    <div className="section">
      <div className="wrapper">
        <div className="tabs">
          <Tabs activeTab={activeTab} onTabChange={handleTabChange} />
        </div>
        <div className="table-header">
          <AddBracket
            fetch={fetch}
            data={editData?.type === "My Sync" ? data.mySync : data?.ourSync}
            editData={editData}
            setEditData={setEditData}
          />
        </div>
        <div className="table-wrapper">
          <table>
            <thead>
              <th>#</th>
              <th>Name</th>
              <th>Tag</th>
              <th>Type</th>
              <th>Start Value</th>
              <th>End Value</th>
              <th>End Value</th>
            </thead>
            <tbody>
              {data[activeTab]?.map((item, index) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.tag}</td>
                  <td>{item.type}</td>
                  <td>{item.startValue}</td>
                  <td>{item.endValue}</td>
                  <td>
                    <button
                      className="info"
                      onClick={() => editDataHandler(item)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MatchSyncBrackets;
