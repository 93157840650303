import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { createAddon } from "../../apis/users";
import authentication from "../../utils/authentication";

const Add = ({ fetch }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    description: "",
    price: 0,
    profiles: 0,
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      if (
        !formData.description?.trim() ||
        !formData.price ||
        !formData.profiles
      ) {
        toast.error("description, profiles and price is required");
        return;
      }
      await createAddon(formData);
      fetch();
      setOpen(false);
      setFormData({
        description: "",
        price: 0,
        profiles: 0,
      });
      toast.success("Addon added successfully");
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [formData]);
  return (
    <div className="modal-container">
      <div className="add-modal">
        <button className="primary" onClick={openHandler}>
          Add
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>Add Addon</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>
                  Description
                  <span className="required">*</span>
                </label>
                <input
                  name="description"
                  value={formData.description}
                  onChange={changeHandler}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Price
                  <span className="required">*</span>
                </label>
                <input
                  name="price"
                  type="number"
                  value={formData.price}
                  onChange={changeHandler}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  profiles
                  <span className="required">*</span>
                </label>
                <input
                  name="profiles"
                  type="number"
                  value={formData.profiles}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
          <div className="footer">
            <button onClick={submitHandler} type="button" className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
