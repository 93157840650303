import React, { useState, useCallback, useEffect } from "react";
import { getNotifications } from "../../apis/users";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const Notifications = () => {
  const [data, setData] = useState([]);

  const fetchNotifications = useCallback(async () => {
    try {
      const apiResponse = await getNotifications();
      if (apiResponse?.data?.data?.length > 0) {
        setData([...apiResponse?.data?.data]);
      } else {
        setData([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="section">
      <div className="wrapper">
        <div className="table-wrapper">
          <table>
            <thead>
              <th>Sl. No.</th>
              <th>Date</th>
              <th>Message</th>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
