import React, { useState, useCallback } from "react";

const Cases = ({ allUsers, cases }) => {
  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className="modal-container">
      <div className="modal-opener">
        <button
          className="primary"
          style={{ marginLeft: "0px" }}
          onClick={openHandler}
        >
          See match report
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper full">
          <div className="header">
            <h2>Match Report</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <ul className="lists">
              <div className="table-wrapper">
                <table>
                  <thead>
                    <th>Paramter</th>
                    <th>Suitor Value</th>
                    <th>Suitor Prefered Value</th>
                    <th>Preference Scale</th>
                    <th>Preference Value in %</th>
                    <th>Maximun Score</th>
                    <th>Score Obtained</th>
                    <th>Match Type</th>
                    <th>Partner Value</th>
                    <th>Partner Preference</th>
                  </thead>
                  <tbody>
                    {cases?.map((cas, index) => (
                      <tr key={index}>
                        <td>{cas?.preference}</td>
                        <td>{cas.matchMaker?.value || cas.parameter}</td>
                        <td>
                          {cas?.yesOrNo !== undefined
                            ? cas?.preference ===
                              "Open to relocation to a different city"
                              ? cas?.yesOrNo === "No"
                                ? "No"
                                : `${
                                    cas.yesOrNo
                                  }(${allUsers[0]?.relocationCities
                                    ?.map((item) => item.name)
                                    ?.join(", ")})`
                              : cas.yesOrNo
                            : cas?.options}
                        </td>
                        <td>{cas?.scorecard}</td>
                        <td>{cas?.max}%</td>
                        <td>{cas?.maxPoint}</td>
                        <td>{cas?.point?.toFixed(2)}</td>
                        <td>
                          {cas?.point === cas?.maxPoint
                            ? "Direct"
                            : cas?.point > 0
                            ? "Partial"
                            : "No Match"}
                        </td>
                        <td>{cas?.value}</td>
                        <td>
                          {cas?.matchMaker?.yesOrNo !== undefined &&
                          cas?.matchMaker?.yesOrNo
                            ? cas?.preference ===
                              "Open to relocation to a different city"
                              ? cas?.matchMaker?.yesOrNo === "No"
                                ? "No"
                                : `${
                                    cas?.matchMaker?.yesOrNo
                                  }(${allUsers[0]?.matchMaker?.relocationCities
                                    ?.map((item) => item.name)
                                    ?.join(", ")})`
                              : cas?.matchMaker?.yesOrNo
                            : cas?.matchMaker?.options}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ fontWeight: "bold" }}>100%</td>
                      <td style={{ fontWeight: "bold" }}>
                        {allUsers[0]?.maximumScore}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {allUsers[0]?.scoresObtained}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ backgroundColor: "#000000", color: "#D8A225" }}
                      >
                        My Sync
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {allUsers[0]?.matchPercentage}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ backgroundColor: "#000000", color: "#D8A225" }}
                      >
                        Our Sync
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {allUsers[0]?.mutualSync}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <br />
                  </tbody>
                </table>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cases;
