import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateDegradingPercentage } from "../../apis/preferences";
import authentication from "../../utils/authentication";

const Edit = ({ data, fetch }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(data?.degradingPercentage || 0);

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = (value) => {
    setValue(value);
  };

  const submitHandler = useCallback(async () => {
    try {
      if (value === data.degradingPercentage) {
        toast.error("Please enter a new value");
      } else {
        await updateDegradingPercentage(data?._id, {
          degradingPercentage: value,
        });
        fetch();
        toast.success("Degrading percentage updated successfully");
        setOpen(false);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [value, data]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button className="info" onClick={openHandler}>
          Edit
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>dit {data?.name} Degrading Percentage</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>
                  Degrading Percentage <span className="required">*</span>
                </label>
                <input
                  type="number"
                  value={value}
                  onChange={(e) => changeHandler(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="footer">
            <button onClick={submitHandler} type="button" className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
