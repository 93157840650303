import React, { useState, useCallback, useEffect } from "react";
import {
  matchMaking,
  searchUsers,
  getAllMatchSyncBrackets,
} from "../../apis/users";
import Cases from "../../components/users/Cases";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";
import "./MatchMaking.css";

const MatchMaking = () => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [cases, setCases] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMatcher, setSelectedMatcher] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedMatcherName, setSelectedMatcherName] = useState("");
  const [searchedUserName, setSearchedUserName] = useState("");
  const [searchedMatcherName, setSearchedMatcherName] = useState("");
  const [ourSyncBrackets, setOurSyncBrackets] = useState([]);
  const [mySyncBrackets, setMySyncBrackets] = useState([]);

  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [matchGender, setMatchGender] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSelectedUserInputChange = (e) => {
    const value = e.target.value;
    setSearchedUserName(value);
    filterSuggestions(value);
    setShowSuggestions(value !== "");
  };

  const handleMatchUserInputChange = (e) => {
    const value = e.target.value;
    setSearchedMatcherName(value);
    filterSuggestions(value);
    setShowSuggestions(value !== "");
  };

  const filterSuggestions = (value) => {
    setSuggestedUsers(
      users.filter((user) =>
        user?.name?.toLowerCase().includes(value?.toLowerCase())
      )
    );
  };

  const handleSelectUserSuggestionClick = (user) => {
    setSearchedUserName(user?.name);
    setMatchGender(user.preferredGender);
    setSelectedUser(user?._id);
    setShowSuggestions(false);
  };

  const handleMatchUserSuggestionClick = (user) => {
    setSearchedMatcherName(user?.name);
    setSelectedMatcher(user?._id);
    setShowSuggestions(false);
  };

  const fetchAllUsers = useCallback(async () => {
    try {
      const apiResponse = await searchUsers();
      if (apiResponse?.data?.data?.length > 0) {
        setUsers(apiResponse.data.data);
      } else {
        setUsers([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  const fetchMatchSyncBrackets = useCallback(async () => {
    try {
      const apiResponse = await getAllMatchSyncBrackets();
      if (apiResponse?.data?.data) {
        setMySyncBrackets([...apiResponse?.data?.data?.mySync]);
        setOurSyncBrackets([...apiResponse?.data?.data?.ourSync]);
      } else {
        setMySyncBrackets([]);
        setOurSyncBrackets([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  const matchMakingHandler = useCallback(async () => {
    if (selectedUser && selectedMatcher && selectedMatcher !== selectedUser) {
      try {
        const apiResponse = await matchMaking(selectedUser, selectedMatcher);
        if (apiResponse?.data?.data?.length > 0) {
          setData([...apiResponse.data.data]);
        } else {
          setData([]);
        }
      } catch (error) {
        setData([]);
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    }
  }, [selectedUser, selectedMatcher, data]);

  useEffect(() => {
    matchMakingHandler();
  }, [selectedUser, selectedMatcher]);

  useEffect(() => {
    fetchAllUsers();
    fetchMatchSyncBrackets();
  }, []);

  return (
    <div className="section">
      <div className="wrapper">
        <div className="filters">
          {/* <div className="input-wrapper">
            <label>Select User</label>
            <select
              value={selectedUser}
              onChange={(event) =>
                selectedUserChangeHandler(event.target.value)
              }
            >
              <option value="" style={{ display: "none" }}></option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name + " (" + user.gender + ")"}
                </option>
              ))}
            </select>
          </div>
          <div className="input-wrapper">
            <label>Select Match Making User</label>
            <select
              value={selectedMatcher}
              onChange={(event) =>
                selectedMatcherChangeHandler(event.target.value)
              }
            >
              <option value="" style={{ display: "none" }}></option>
              {users
                .filter((user) => user._id !== selectedUser)
                .map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.name + " (" + user.gender + ")"}
                  </option>
                ))}
            </select>
          </div> */}

          {/* Search button */}
          <div className="input-wrapper">
            <label>Select User</label>
            <input
              type="text"
              placeholder="Search User Name"
              value={searchedUserName}
              onChange={handleSelectedUserInputChange}
              className="search-input"
            />
            {showSuggestions && (
              <ul className="search-suggestions">
                {users
                  .filter(
                    (user) =>
                      user.name &&
                      user.name
                        .toLowerCase()
                        .search(searchedUserName.trim().toLowerCase()) !== -1
                  )
                  .map((user) => (
                    <li
                      key={user._id}
                      onClick={() => handleSelectUserSuggestionClick(user)}
                    >
                      {user.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>

          <div className="input-wrapper">
            <label>Select Match Making User</label>
            <input
              type="text"
              placeholder="Search Matching User Name"
              value={searchedMatcherName}
              onChange={handleMatchUserInputChange}
              className="search-input"
            />
            {showSuggestions && (
              <ul className="search-suggestions">
                {users
                  .filter(
                    (user) =>
                      user.name
                        .toLowerCase()
                        .search(searchedMatcherName.toLowerCase().trim()) !==
                        -1 && user.gender === matchGender
                  )
                  .map((user) => (
                    <li
                      key={user._id}
                      onClick={() => handleMatchUserSuggestionClick(user)}
                    >
                      {user.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {/* {cases?.length > 0 && <Cases cases={cases} />} */}
        {data?.length > 0 && (
          <div className="table-wrapper">
            <table>
              <thead>
                <th>Name</th>
                <th>Gender</th>
                <th>Preferred Gender</th>
                <th>MySync</th>
                <th>MySync Tag</th>
                <th>OurSync</th>
                <th>OurSync Tag</th>
                <th>Name of RM</th>
                <th>Subscription plan</th>
                <th>Report</th>
              </thead>
              <tbody>
                {data.map((user) => (
                  <tr key={user._id} className="centered-tr">
                    <td>{user.name}</td>
                    <td>{user.gender}</td>
                    <td>{user.preferredGender}</td>
                    <td>{user.matchPercentage}</td>
                    <td>
                      {
                        mySyncBrackets?.filter(
                          (el) =>
                            user.matchPercentage >= el.startValue &&
                            user.matchPercentage <= el.endValue
                        )[0]?.name
                      }
                    </td>

                    <td>{user.mutualSync}</td>
                    <td>
                      {
                        ourSyncBrackets?.filter(
                          (el) =>
                            user.mutualSync >= el.startValue &&
                            user.mutualSync <= el.endValue
                        )[0]?.name
                      }
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td onClick={() => setSelectedMatcherName(user.name)}>
                      <Cases
                        allUsers={data}
                        selectedUserName={selectedUserName}
                        searchedMatcherName={searchedMatcherName}
                        selectedMatcherName={user.matchMaker.name}
                        cases={user.cases}
                        totalScore={user.matchPercentage}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchMaking;
