import axios from "axios";

export default (endpoint, method, data, headers) =>
  axios({
    url: `https://api.thesoulsync.com/api/v1/${endpoint}`,
    method,
    data,
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
